<template>
  <section >
    <h2 class="titulo-links-escolha">EDITAR ESCOLAS DO COORDENADOR</h2>
    <pm-Card>
      <template v-slot:content>
        <div class="p-fluid formgrid grid">
          <div class="field col-12 md:col-12 lg:col-12">
            <h4>
              <strong>Nome: {{ coordenador.nome }}</strong>
            </h4>
          </div>
          <div class="field col-12 md:col-4 lg:col-4">
            <h5>
              <b>Escolha as Escolas</b>
            </h5>
            <div class="form-check" v-for="n in escolasALL" :key="n">
              <label class="form-check-label">
                <input
                  type="checkbox"
                  class="form-check-input"
                  v-model="escolasArr"
                  :value="n.id"
                />{{ n.nome }}
              </label>
            </div>
          </div>
          <div class="field col-12 md:col-6 lg:col-6">
            <h5>
              <b>Escolha os segmentos que o coordenador vai acompanhar</b>
            </h5>
            <div class="form-check">
              <label class="form-check-label">
                <input
                  type="checkbox"
                  class="form-check-input"
                  v-model="coordenador.pre_escola"
                  :value="1"
                  @change="verificarSegmentoSelecionado"
                />Pre Escola (creche)
              </label>
            </div>
            <div class="form-check">
              <label class="form-check-label">
                <input
                  type="checkbox"
                  class="form-check-input"
                  v-model="coordenador.fundamental1"
                  :value="1"
                  @change="verificarSegmentoSelecionado"
                />
                Fundamental I
              </label>
            </div>
            <div class="form-check">
              <label class="form-check-label">
                <input
                  type="checkbox"
                  class="form-check-input"
                  v-model="coordenador.fundamental2"
                  :value="1"
                  @change="verificarSegmentoSelecionado"
                />Fundamental II
              </label>
            </div>
            <div class="form-check">
              <label class="form-check-label">
                <input
                  type="checkbox"
                  class="form-check-input"
                  v-model="coordenador.ern"
                  :value="1"
                  @change="verificarSegmentoSelecionado"
                />ERN
              </label>
            </div>
            <div class="form-check">
              <label class="form-check-label">
                <input
                  type="checkbox"
                  class="form-check-input"
                  v-model="coordenador.eja1"
                  :value="1"
                  @change="verificarSegmentoSelecionado"
                />EJA ( 2 fase até a 5 fase)
              </label>
            </div>
            <div class="form-check">
              <label class="form-check-label">
                <input
                  type="checkbox"
                  class="form-check-input"
                  v-model="coordenador.eja2"
                  :value="1"
                  @change="verificarSegmentoSelecionado"
                />EJA ( 6 fase até a 9 fase)
              </label>
            </div>
          </div>
        </div>
        <div class="grid grid-nogutter justify-content-between">
          <pm-Button label="Voltar"  class="p-button-danger" icon="pi pi-angle-left" iconPos="left"
               @click="this.$router.push({ name: 'coordenacao-servidores', params: { pg: this.pg} })" />

          <pm-Button  class="p-button-success"
             label="Editar" @click="editar" v-if="escolasArr.length > 0 && segmentoSelecionado"
             icon="pi pi-check" iconPos="right"
          />
        </div>
      </template>
    </pm-Card>
  </section >
</template>

<script>

import { Calendario } from "@/class/calendario.js";
import { Servidores } from "@/class/servidores.js";

import vueMask from 'vue-jquery-mask';
import store_token_info from "@/store/store_token_info.js";

import { Coordenador } from "@/class/coordenador.js";
import { Escola } from "@/class/escolas.js";

export default {
  props: {
    id:{},
    pg:{}
  },
  components: {

  },
  name: 'Professor',
  data () {
    return {
      segmentoSelecionado: false,
      coordenador: {
        nome:'',
        servidor_id: null,
        escolas:'',
        ano:0,

        pre_escola:0,
        fundamental1:0,
        fundamental2:0,
        ern:0,
        eja1:0,
        eja2:0,
      },
      escolasALL: [],
      escolasArr: [],
    }
  },
  methods: {
    async buscarCalendarioAtual() {
      let data = await Calendario.calendarioAtual();
      this.coordenador.ano = data.data[0].ano;
    },
    async escolas(){
       let esc =  await Escola.obtemTodos();
       this.escolasALL = esc.data;
    },
    verificarSegmentoSelecionado() {
      this.segmentoSelecionado =  this.coordenador.pre_escola || this.coordenador.fundamental1 || this.coordenador.fundamental2 || this.coordenador.ern || this.coordenador.eja1 || this.coordenador.eja2;
    },
    async editarOption(id){
      try {
        let data = await Servidores.obtemUm(id);
        let data1 = await Coordenador.obtemUmServidor(data.data[0].id);
        this.coordenador.nome = data.data[0].nome;
        this.coordenador.escolas = data1.data.escolas;
        this.coordenador.servidor_id = data.data[0].id;
        this.coordenador.id = data1.data.id;

        if(data1.data.pre_escola == 1){
          this.coordenador.pre_escola = true;
        }
        if(data1.data.fundamental1 == 1){
          this.coordenador.fundamental1 = true;
        }
        if(data1.data.fundamental2 == 1){
          this.coordenador.fundamental2 = true;
        }
        if(data1.data.ern == 1){
          this.coordenador.ern = true;
        }
        if(data1.data.eja1 == 1){
          this.coordenador.eja1 = true;
        }
        if(data1.data.eja2 == 1){
          this.coordenador.eja2 = true;
        }
        this.verificarSegmentoSelecionado();
        this.escolasArr = [];
        let findEscolas = data1.data.escolas;
        findEscolas = findEscolas.split("-");
        for (var i = 0; i < findEscolas.length; i++) {
          if (findEscolas[i] == "-" && findEscolas[i] != 0)
            continue;
          this.escolasArr.push(parseInt(findEscolas[i]));
        }
      } catch (e) {
        // console.log(e);
      }
    },
    async editar() {
      try {
        let esc = '';
        for (const c of this.escolasArr) {
          esc += c + '-';
        }
        if(esc.length > 1){
          esc = esc.substring(0, esc.length - 1);
        }
        this.coordenador.escolas = esc;


        let data = await Coordenador.alterar(this.coordenador);
        this.$vaToast.init({
          message: "Editado com sucesso!",
          iconClass: 'fa-star-o',
          position: 'top-right',
          color:'success',
          duration: 2500,
          fullWidth: false,
        })
        this.$router.push({name: 'coordenacao-servidores', params: { pg: this.pg}});
      }
      catch(e) {
        this.$vaToast.init({
          message: "Erro ao Editar!",
          iconClass: 'fa-star-o',
          position: 'top-right',
          color:'danger',
          duration: 2500,
          fullWidth: false,
        })
      }
    },
  },
  mounted() {
    if (this.id == null) {
      this.$router.push({name: 'coordenacao-servidores'});
    }
    else {
      this.editarOption(this.id);
      this.escolas();
      this.buscarCalendarioAtual();
    }
  },
}
</script>

<style>
  .row.row-inside {
    max-width: none;
  }

  .form-control:focus {
    background-color: #f6f7f6;
    border-color: #673ab7;
    box-shadow: none;
  }
  .form-control {
    background-color: #f6f7f6;
    border: 1px solid #f6f6f6;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
     margin: 0;
  }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }


  .va-input-wrapper, fieldset {
    margin-bottom: 0.5rem;
  }
</style>
